@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Caveat+Brush);
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  margin: 0;
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.5px;
  background: linear-gradient(300deg, deepskyblue, darkviolet, blue);
  background-size: 180% 180%;
  -webkit-animation: gradient-animation 18s ease infinite;
          animation: gradient-animation 18s ease infinite;
}

@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  color: white;
}

button a:hover {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

* {
  font-family: "Varela Round";
}

.combo-counter {
  font-family: "Caveat Brush";
  font-size: 60px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  color: #479890;
  text-shadow: -3px -3px 0 #000, 0 -3px 0 #000, 3px -3px 0 #000, 3px 0 0 #000,
    3px 3px 0 #000, 0 3px 0 #000, -3px 3px 0 #000, -3px 0 0 #000;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.react-chessboard-draggable-piece {
  cursor: grab;
}
.dragged-piece {
  cursor: grab;
}

.accuracy-emoji {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8em;
  pointer-events: none;
  z-index: 6;
}

